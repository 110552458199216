<template>
  <div>
    <Header></Header>
    <div class="container-fluid px-4">
      <div class="content">
        <div class="sidebar-toggle">
          <a @click.prevent="toggleMenu()"><i class="icon-menu-m"></i></a>
        </div>
        <Sidebar></Sidebar>
        <router-view />
        <b-modal
          id="exampleModal"
          hide-backdrop
          hide-header
          centered
          hide-footer
        >
          <div class="row justify-content-end h-100">
            <div class="col-11">
              <div
                class="b-wrapper h-100 text-center d-flex align-items-center w-100"
              >
                <div class="b-content flex-grow-1">
                  <h1 class="text-primary">Happy Birthday</h1>
                  <h2 style="margin-left: 8px" class="mt-3 fw-bold mb-3">
                    {{ BirthdayMessage }}
                  </h2>
                  <p class="w-75 mx-auto">
                    On your birthday we wish for you that whatever you want most
                    in life it comes to you just the way you imagined it or
                    better. May the joy that you have spread in the past come
                    back to you on this day. Wishing you a very Happy Birthday!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal
          id="modalWorkAnniversary"
          hide-backdrop
          hide-header
          centered
          hide-footer
        >
          <template #modal-title> </template>
          <div class="row justify-content-end h-100">
            <div class="col-12">
              <div
                class="b-wrapper h-100 text-center d-flex align-items-center w-100"
              >
                <div class="b-content flex-grow-1">
                  <p>Congratulation on your</p>
                  <h1 class="mt-3 fw-bold text">
                    <span>WORK</span> <br />
                    ANIVERSARY
                  </h1>
                  <h2 class="mt-3">{{ workAnniversaryMessage }}</h2>
                  <span class="desc"
                    >I extend my heartiest congratulations to you on setting yet
                    another milestone in the journey of your professional life.
                    I wish you a very happy work anniversary!</span
                  >
                </div>
              </div>
            </div>
          </div>
        </b-modal>
        <b-modal id="new-modal" hide-backdrop centered hide-footer>
          <template #modal-title> </template>
          <div class="d-block text-center">
            <p>{{ newEmployeeMsg }}</p>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "../components/Header.vue";
import Sidebar from "../components/Sidebar.vue";
import axios from "axios";
import moment from "moment";
export default {
  components: { Header, Sidebar },
  data() {
    return {
      showMenu: true,
      BirthdayMessage: null,
      workAnniversaryMessage: null,
      newEmployeeMsg: null,
    };
  },
  mounted() {
    if (window.innerWidth < 1200) {
      this.showMenu = false;
    }
    if (localStorage.getItem("events_checked") != 1) {
      axios
        .get("/personal/check-events")
        .then(({ data }) => {
          var bday = moment(data.birthday).format("MM-DD");
          var workAnniversary = moment(data.work_anniversary).format("MM-DD");
          var current = moment(new Date()).format("MM-DD");
          if (
            workAnniversary == current &&
            localStorage.getItem("anniversary_dialog_showed") != 1
          ) {
            this.workAnniversaryMessage = localStorage
              .getItem("name")
              .split(" ")
              .reverse()
              .join(" ");
            this.$bvModal.show("modalWorkAnniversary");
            localStorage.setItem("anniversary_dialog_showed", 1);
          }
          if (
            bday == current &&
            localStorage.getItem("birthday_dialog_showed") != 1
          ) {
            this.BirthdayMessage = localStorage
              .getItem("name")
              .split(" ")
              .reverse()
              .join(" ");
            this.$bvModal.show("exampleModal");
            localStorage.setItem("birthday_dialog_showed", 1);
          }
          if (
            localStorage.getItem("new_employee") == "true" &&
            localStorage.getItem("new_modal_dialog_showed") != 1
          ) {
            this.BirthdayMessage =
              "Welcome to the Devoint Technology Private Limited family! " +
              localStorage.getItem("name").split(" ").reverse().join(" ");
            this.$bvModal.show("new-modal");
            localStorage.setItem("new_modal_dialog_showed", 1);
          }

          localStorage.setItem("events_checked", 1);
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = true;
      document.getElementById("sidebar-content").classList.add("sidebar-open");
    },
  },
};
</script>
