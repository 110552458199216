var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-left",attrs:{"id":"sidebar-content"}},[_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-toggle"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.toggleMenu()}}},[_c('i',{staticClass:"icon-circle-left"})])]),_c('div',{staticClass:"sidebar-wraper"},[_c('ul',{staticClass:"nav flex-column"},[(this.role_id != '7')?_c('span',[_c('li',{staticClass:"nav-item"},[(this.$store.state.permittedActions.includes(1))?_c('router-link',{class:_vm.currentRouteName == 'dashboard'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'dashboard' }}},[_c('i',{staticClass:"icon-dashboard"}),_c('span',[_vm._v("Dashboard")])]):_vm._e()],1),(this.$store.state.permittedActions.includes(2))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'employees'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'employees' }}},[_c('i',{staticClass:"icon-user-tie"}),_c('span',[_vm._v("Employees")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(3))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'attendances'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'attendances' }}},[_c('i',{staticClass:"icon-calendar"}),_c('span',[_vm._v("Attendances")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(4))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'schedules'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'schedules' }}},[_c('i',{staticClass:"icon-alarm"}),_c('span',[_vm._v("Schedules")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(5))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'leaves' ? 'nav-link active' : 'nav-link',attrs:{"to":{ name: 'leaves' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Leaves")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(5))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'SpecialWorkingDays'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'SpecialWorkingDays' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Special Working Days")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(5))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'MonthlyLeaves'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'MonthlyLeaves' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Monthly Leaves")])])],1):_vm._e(),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'holidays'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'holidays' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Holidays")])])],1),(this.$store.state.permittedActions.includes(8))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'users' ? 'nav-link active' : 'nav-link',attrs:{"to":{ name: 'users' }}},[_c('i',{staticClass:"icon-users"}),_c('span',[_vm._v("Users")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(16))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'accessories'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'accessories' }}},[_c('i',{staticClass:"icon-headphones"}),_c('span',[_vm._v("Accessories")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(16))?_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'inventory'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'inventory' }}},[_c('i',{staticClass:"icon-headphones"}),_c('span',[_vm._v("Inventory Management")])])],1):_vm._e(),(this.$store.state.permittedActions.includes(2))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'clock' ? 'nav-link active' : 'nav-link',attrs:{"to":{ name: 'clock' }}},[_c('i',{staticClass:"icon-clock"}),_c('span',[_vm._v("Clock In/Clock Out")])])],1):_vm._e()]):_vm._e(),(this.role_id != '7')?_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" My Links ")]),_c('div',{staticClass:"dropdown-menu sidebar-dropdown",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'personal-dashboard'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'personal-dashboard' }}},[_c('i',{staticClass:"icon-dashboard"}),_c('span',[_vm._v("Dashboard")])]),_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'clock' ? 'nav-link active' : 'nav-link',attrs:{"to":{ name: 'clock' }}},[_c('i',{staticClass:"icon-clock"}),_c('span',[_vm._v("Clock In/Clock Out")])]),_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'my-attendances'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-attendances' }}},[_c('i',{staticClass:"icon-calendar"}),_c('span',[_vm._v("My Attendances")])]),_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'my-schedules'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-schedules' }}},[_c('i',{staticClass:"icon-alarm"}),_c('span',[_vm._v("My Schedules")])]),_c('router-link',{staticClass:"dropdown-item",class:_vm.currentRouteName == 'my-leaves'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-leaves' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("My Leaves")])])],1)]):_c('span',[_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'personal-dashboard'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'personal-dashboard' }}},[_c('i',{staticClass:"icon-dashboard"}),_c('span',[_vm._v("Dashboard")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'clock' ? 'nav-link active' : 'nav-link',attrs:{"to":{ name: 'clock' }}},[_c('i',{staticClass:"icon-clock"}),_c('span',[_vm._v("Clock In/Clock Out")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'my-attendances'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-attendances' }}},[_c('i',{staticClass:"icon-calendar"}),_c('span',[_vm._v("My Attendances")])])],1),_c('li',{staticClass:"nav-item"},[(_vm.$store.state.permittedActions.includes(441))?_c('router-link',{class:_vm.currentRouteName == 'my-schedules'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-schedules' }}},[_c('i',{staticClass:"icon-alarm"}),_c('span',[_vm._v("My Schedules")])]):_vm._e()],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'my-leaves'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-leaves' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("My Leaves")])])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.currentRouteName == 'special-working-days'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'special-working-days' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Special Working Days")])])],1),_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.holiday()}}},[_c('router-link',{class:_vm.currentRouteName == 'my-holidays'
                  ? 'nav-link active'
                  : 'nav-link',attrs:{"to":{ name: 'my-holidays' }}},[_c('i',{staticClass:"icon-exit"}),_c('span',[_vm._v("Holidays")])])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }