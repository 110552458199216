<template>
  <header class="header">
        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container-fluid px-4 position-relative">
                <a class="navbar-brand" href="">
                    <img :src="asset('images/site-logo.png')" alt=""/>
                </a>
                <div class="header-right">
                    <div class="dropdown ms-3">
                        <!-- <button class="dropdown-toggle border-0" type="button" id="dropdownSetting" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="icon-setting"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownSetting">
                            <li><a class="dropdown-item" href="#">Clock In/Clock Out</a></li>
                            <li><router-link class="dropdown-item" to="">New Employee</router-link></li>
                            <li><router-link class="dropdown-item" to="">Company</router-link></li>
                            <li><router-link class="dropdown-item" to="">Department</router-link></li>
                            <li><router-link class="dropdown-item" to="">Job Title</router-link></li>
                            <li><router-link class="dropdown-item" to="">Leave Type</router-link></li>
                            <li><router-link class="dropdown-item" to="">Leave Groups</router-link></li>
                        </ul> -->
                        <b-dropdown v-if="role_id != 7" id="dropdown-right" right text="Dropdown" size="sm" variant="primary" class="dropdown-toggle custom-dropdown">
                            <template #button-content >
                                <i class="icon-setting"></i>
                            </template>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(21)"><router-link class="dropdown-item" :to="{ name: 'new-employee'}">New Employee</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(11)"><router-link class="dropdown-item" :to="{name: 'companies' }">Company</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(12)"><router-link class="dropdown-item" :to="{name: 'departments' }">Department</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(13)"><router-link class="dropdown-item" :to="{name: 'job-title' }">Job Title</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(14)"><router-link class="dropdown-item" :to="{name: 'leave-type' }">Leave Type</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" v-if="$store.state.permittedActions.includes(15)"><router-link class="dropdown-item" :to="{name: 'leave-groups' }">Leave Groups</router-link></b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <div class="dropdown border-0">
                        <b-dropdown id="dropdown-right" right text="Dropdown" size="sm" variant="primary" class="dropdown-toggle custom-dropdown">
                            <template #button-content class="border-0">
                                <img :src="avatar != ''
                                ? $baseUrl+'/faces/'+avatar
                                : asset('/images/faces/default_user.jpg')" alt="Avatar" class="avatar">
                            </template>
                            <b-dropdown-item href="#"><router-link :to="{name: 'admin-profile'}" class="dropdown-item">My Profile</router-link></b-dropdown-item>
                            <b-dropdown-item href="#"><a @click.prevent="logout" class="dropdown-item">Log Out</a></b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
<style>
.custom-dropdown button{
    border: none;
}
.custom-dropdown button:hover {
    border: none;
    background-color: initial;
}
</style>
<script>
import axios from 'axios';
require('../axios');
export default {
     data(){
        return {
            role_id: localStorage.getItem('role_id'),
            avatar:  localStorage.getItem('avatar'),
            name:  localStorage.getItem('name')
        }
    },
    methods: {
    asset(path) {
        var base_path =window.location.origin;
        return base_path + '/' +path;
    },
    logout(){
        axios.get('logout').then(({data}) =>{
            localStorage.clear();
            this.$router.push('/login');
        })
    }
    }
}
</script>
