<template>
  <div class="content-left" id="sidebar-content">
    <div class="sidebar">
      <div class="sidebar-toggle">
        <a @click.prevent="toggleMenu()"><i class="icon-circle-left"></i></a>
      </div>
      <div class="sidebar-wraper">
        <ul class="nav flex-column">
          <span v-if="this.role_id != '7'">
            <li class="nav-item">
              <router-link
                v-if="this.$store.state.permittedActions.includes(1)"
                :to="{ name: 'dashboard' }"
                :class="
                  currentRouteName == 'dashboard'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-dashboard"></i>
                <span>Dashboard</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(2)"
            >
              <router-link
                :to="{ name: 'employees' }"
                :class="
                  currentRouteName == 'employees'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-user-tie"></i>
                <span>Employees</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(3)"
            >
              <router-link
                :to="{ name: 'attendances' }"
                :class="
                  currentRouteName == 'attendances'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-calendar"></i>
                <span>Attendances</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(4)"
            >
              <router-link
                :to="{ name: 'schedules' }"
                :class="
                  currentRouteName == 'schedules'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-alarm"></i>
                <span>Schedules</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(5)"
            >
              <router-link
                :to="{ name: 'leaves' }"
                :class="
                  currentRouteName == 'leaves' ? 'nav-link active' : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Leaves</span>
              </router-link>
            </li>

            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(5)"
            >
              <router-link
                :to="{ name: 'SpecialWorkingDays' }"
                :class="
                  currentRouteName == 'SpecialWorkingDays'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Special Working Days</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(5)"
            >
              <router-link
                :to="{ name: 'MonthlyLeaves' }"
                :class="
                  currentRouteName == 'MonthlyLeaves'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Monthly Leaves</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'holidays' }"
                :class="
                  currentRouteName == 'holidays'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Holidays</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(8)"
            >
              <router-link
                :to="{ name: 'users' }"
                :class="
                  currentRouteName == 'users' ? 'nav-link active' : 'nav-link'
                "
              >
                <i class="icon-users"></i>
                <span>Users</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(16)"
            >
              <router-link
                :to="{ name: 'accessories' }"
                :class="
                  currentRouteName == 'accessories'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-headphones"></i>
                <span>Accessories</span>
              </router-link>
            </li>
            <!-- 
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(16)"
            >
              <router-link
                :to="{ name: 'accessories-category' }"
                :class="
                  currentRouteName == 'accessories-category'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-headphones"></i>
                <span>Accessories Category</span>
              </router-link>
            </li>
            <li class="nav-item dropdown" v-if="this.role_id != '7'">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Accessory Management
              </a>
              <div
                class="dropdown-menu sidebar-dropdown"
                aria-labelledby="navbarDropdown"
              >
                <router-link
                  :to="{ name: 'field' }"
                  class="dropdown-item"
                  :class="
                    currentRouteName == 'field' ? 'nav-link active' : 'nav-link'
                  "
                >
                  <i class="icon-dashboard"></i>
                  <span>Field Management</span>
                </router-link>
                <router-link
                  :to="{ name: 'field' }"
                  class="dropdown-item"
                  :class="
                    currentRouteName == 'field' ? 'nav-link active' : 'nav-link'
                  "
                >
                  <i class="icon-clock"></i>
                  <span>Accessory Category</span>
                </router-link>
                <router-link
                  :to="{ name: 'my-attendances' }"
                  class="dropdown-item"
                  :class="
                    currentRouteName == 'my-attendances'
                      ? 'nav-link active'
                      : 'nav-link'
                  "
                >
                  <i class="icon-calendar"></i>
                  <span>Accessory Management</span>
                </router-link>
              </div>
            </li> -->
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(16)"
            >
              <router-link
                :to="{ name: 'inventory' }"
                :class="
                  currentRouteName == 'inventory'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-headphones"></i>
                <span>Inventory Management</span>
              </router-link>
            </li>
            <li
              class="nav-item"
              v-if="this.$store.state.permittedActions.includes(2)"
            >
              <router-link
                class="dropdown-item"
                :to="{ name: 'clock' }"
                :class="
                  currentRouteName == 'clock' ? 'nav-link active' : 'nav-link'
                "
              >
                <i class="icon-clock"></i>
                <span>Clock In/Clock Out</span>
              </router-link>
            </li>
          </span>
          <li class="nav-item dropdown" v-if="this.role_id != '7'">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              My Links
            </a>
            <div
              class="dropdown-menu sidebar-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <router-link
                :to="{ name: 'personal-dashboard' }"
                class="dropdown-item"
                :class="
                  currentRouteName == 'personal-dashboard'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-dashboard"></i>
                <span>Dashboard</span>
              </router-link>
              <router-link
                :to="{ name: 'clock' }"
                class="dropdown-item"
                :class="
                  currentRouteName == 'clock' ? 'nav-link active' : 'nav-link'
                "
              >
                <i class="icon-clock"></i>
                <span>Clock In/Clock Out</span>
              </router-link>
              <router-link
                :to="{ name: 'my-attendances' }"
                class="dropdown-item"
                :class="
                  currentRouteName == 'my-attendances'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-calendar"></i>
                <span>My Attendances</span>
              </router-link>
              <router-link
                :to="{ name: 'my-schedules' }"
                class="dropdown-item"
                :class="
                  currentRouteName == 'my-schedules'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-alarm"></i>
                <span>My Schedules</span>
              </router-link>
              <router-link
                :to="{ name: 'my-leaves' }"
                class="dropdown-item"
                :class="
                  currentRouteName == 'my-leaves'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>My Leaves</span>
              </router-link>
            </div>
          </li>
          <span v-else>
            <li class="nav-item">
              <router-link
                :to="{ name: 'personal-dashboard' }"
                :class="
                  currentRouteName == 'personal-dashboard'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-dashboard"></i>
                <span>Dashboard</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'clock' }"
                :class="
                  currentRouteName == 'clock' ? 'nav-link active' : 'nav-link'
                "
              >
                <i class="icon-clock"></i>
                <span>Clock In/Clock Out</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'my-attendances' }"
                :class="
                  currentRouteName == 'my-attendances'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-calendar"></i>
                <span>My Attendances</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                v-if="$store.state.permittedActions.includes(441)"
                :to="{ name: 'my-schedules' }"
                :class="
                  currentRouteName == 'my-schedules'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-alarm"></i>
                <span>My Schedules</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'my-leaves' }"
                :class="
                  currentRouteName == 'my-leaves'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>My Leaves</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'special-working-days' }"
                :class="
                  currentRouteName == 'special-working-days'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Special Working Days</span>
              </router-link>
            </li>
            <li class="nav-item" v-on:click="holiday()">
              <router-link
                :to="{ name: 'my-holidays' }"
                :class="
                  currentRouteName == 'my-holidays'
                    ? 'nav-link active'
                    : 'nav-link'
                "
              >
                <i class="icon-exit"></i>
                <span>Holidays</span>
              </router-link>
            </li>
          </span>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      showMenu: true,
      windowHeight: window.innerHeight,
      role_id: localStorage.getItem("role_id"),
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    if (this.windowHeight < 992) {
      this.showMenu = false;
    }
  },
  methods: {
    toggleMenu() {
      this.showMenu = false;
      document
        .getElementById("sidebar-content")
        .classList.remove("sidebar-open");
    },
  },
};
</script>
